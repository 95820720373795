<template>
  <div class="mt-2 container-fluid">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-user"></i> Usuarios             
          </div>
          <div class="card-body">  
            <div class="row mb-2">
              <div class="col-lg-4">

                <div class="form-group">                       
                  <div class="input-group">
                    <input v-model="valor" type="text" class="form-control form-control-sm" placeholder="Buscar por Usuario..." v-on:keyup.enter="listar()">
                    <a class="btn btn-secondary btn-sm" @click="listar"><i class="fa fa-search"></i></a>
                    <a class="btn btn-secondary btn-sm" @click="valor='';listar()"><i class="fa fa-times"></i></a>
                  </div>
                </div>

              </div>
              <div class="col-lg-4">
                <a class="btn btn-success btn-sm" @click="showModal()"><i class="fa fa-plus"></i> Nuevo</a>
              </div>
            </div>
            <div class="row">             
              <div class="col md-12">
                <div class="card border-primary">                    
                  <div class="card-body">  
                    <div class="table-responsive" style="overflow-y:scroll; height:550px;">
                      <table class="table table-sm table-bordered table-condensed">
                        <thead>
                          <tr class="table-secondary">
                            <th class="text-center">Nro.</th>
                            <th>Usuario</th>
                            <th>Persona</th>
                            <th>Repartición</th>
                            <th>Rol </th>
                            <th>Estado</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>                          
                        <tbody>
                          <tr v-for="(item, index) in registros" :key="index" :class="item.estado!==1 ? 'text-muted' : ''">
                            <td class="text-center">{{ ((currentPage*perPage) - perPage) + (index+1)}}</td>
                            <td>
                              {{item.usuario}}                   
                            </td>
                            <td>
                              {{ item.nombrecompleto }} <br>
                              {{item.dip}}                   
                            </td>
                            <td>
                              {{item.reparticion}}                   
                            </td>
                            <td>
                              {{item.rol}}                 
                            </td>                           
                            <td>
                              <span class="badge bg-success" v-if="item.estado==1">Activo</span>
                              <span class="badge bg-danger" v-else>Inactivo</span>
                            </td>
                            <td>
                              <a class="btn btn-sm btn-secondary ml-4" @click="reset(item)" v-if="item.estado==1"><i class="fa fa-key"></i></a>
                              <a class="btn btn-sm btn-warning mr-4" @click="showModal(item)" v-if="item.estado==1"><i class="fa fa-edit"></i></a>
                              <a class="btn btn-sm btn-danger ml-4" @click="baja(item)" v-if="item.estado==1"><i class="fa fa-ban"></i></a>
                              <a class="btn btn-sm btn-success ml-4" @click="alta(item)" v-else><i class="fa fa-arrow-up"></i></a>
                            </td>                      
                          </tr>
                        </tbody>
                      </table>                        
                    </div>

                    <div class="overflow-auto">
                      <b-pagination
                        class="mb-1 mt-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-text="<<"
                        prev-text="<"
                        next-text=">"
                        last-text=">>"
                        size="sm"
                        @change="cambio"
                        v-if="rows>10"
                      ></b-pagination>
                      <small class="text-muted">Página: {{ currentPage}} | Registros: del {{ ((currentPage*perPage) - perPage)+1 }} al {{currentPage*perPage}} | Total:  {{rows}}</small>                       
                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </div> 
      </div>
    </div>
    <Loading v-show="cargando" />

    <b-modal
        class="modal fade"
        :title="tituloModal"
        size="md"
        hide-footer
        ref="modalAm"
        :no-close-on-backdrop="true"
    >
      <div class="container">
        <form @submit.prevent="procesar">
          <div class="row">
            <label>Usuario:</label>
            <span
              class="lb-error"
              v-if="!$v.registro.usuario.required"
              >Campo requerido
            </span>
            <input
              class="form-control"
              :class="{ 'is-invalid': $v.registro.usuario.$error }"
              v-model.trim="$v.registro.usuario.$model"
              placeholder="Usuario..." 
              required="true"     
              :disabled="edicion"   
            >
          </div>
          <div class="row">
            <label>Rol:</label>
            <span
              class="lb-error"
              v-if="!$v.registro.rol.required"
              >Campo requerido
            </span>
            <select
              class="form-select form-select"         
              :class="{ 'is-invalid': $v.registro.rol.$error }"               
              v-model.trim="$v.registro.rol.$model"
              required
            >
              <option v-for="(item,index) in roles" :key="index" :value="item.id">{{item.descripcion}}</option>
            </select>
          </div>
          <div class="row">
            <label>Persona:</label>            
            <select
              class="form-select form-select"         
              :class="{ 'is-invalid': $v.registro.idpersona.$error }"               
              v-model.trim="$v.registro.idpersona.$model"
              required
              :disabled="edicion"
            >
              <option v-for="(item,index) in personas" :key="index" :value="item.idpersona">{{item.paterno}} {{item.materno}} {{item.nombres}} </option>
            </select>
          </div>
          <div class="row">
            <label>Repartición:</label>            
            <select
              class="form-select form-select"         
              :class="{ 'is-invalid': $v.registro.id_reparticion.$error }"               
              v-model.trim="$v.registro.id_reparticion.$model"
              required
            >
              <option v-for="(item,index) in reparticiones" :key="index" :value="item.id_reparticion">{{item.descripcion}}</option>
            </select>
          </div>
          <hr>
          <div class="row">
            <div class="col-lg-6">
              <a class="btn btn-secondary float-left" @click="hideModal" >Cancelar</a>
            </div>
            <div class="col-lg-6">
              <button type="submit" v-if="!edicion" class="btn btn-primary float-end" :disabled="$v.$invalid"><i class="fa fa-save"></i> Registrar</button>
              <button type="submit" v-if="edicion" class="btn btn-warning float-end" :disabled="$v.$invalid"><i class="fa fa-edit"></i> Editar</button>
            </div>
          </div>
        </form>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { required, minLength , helpers, maxLength} from "vuelidate/lib/validators";
import Loading from '@/components/Loading';
import moment from 'moment';

const letras = helpers.regex("alpha", /^[a-zA-ZñÑ ]*$/);
const numero = helpers.regex("numb",/^[0-9]\d*$/);

export default {
  data() {
    return {
      cargando:false,
      registros: [],
      currentPage:1,
      rows:0,
      perPage:10,
      valor:'',
      registro:{
        usuario: '',        
        rol: '',
        idpersona: undefined,
        id_reparticion: undefined
      },
      edicion: false,
      tituloModal: 'Nuevo Registro',
      personas:[],
      reparticiones: [],
      roles: []
    }
  },
  components: {
    Loading    
  },
  
  methods: {
    async listar() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/usuarios/${this.currentPage}/${this.valor}`);
        this.registros = resultados.data.contenido.registros; 
        this.rows = resultados.data.contenido.total;
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    async listarPersonas() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/listarPersonas`);
        this.personas = resultados.data.contenido; 
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    async listarReparticiones() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/listarReparticion`);
        this.reparticiones = resultados.data.contenido.registros; 
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    cambio(value){
      this.currentPage = value;
      this.listar();
    },
    formatoFecha(fecha){
      return moment(fecha).format('DD/MM/YYYY');
    },
    formatoHora(fecha){
      return moment(fecha).format('HH:mm:ss');
    },
    procesar(){
      this.$v.$touch();
      if(this.$v.invalid){
        return;
      }
      else{
        if(this.edicion){this.editar();}else{this.guardar();}
      } 
    },
    async guardar(){
      this.cargando = true;
      try {
        await this.axios.post(`/usuario`, this.registro);        
        this.hideModal();
        this.cargando = false;        
        this.listar();
        this.$alert("Se agregó correctamente","Atención","success"); 
      } catch (error) {
        console.log(error);
        this.cargando = false;
      }
    },
    async editar(){
      this.cargando = true;
      try {
        await this.axios.put(`/usuarioEditado/${this.registro.idusuario}`, this.registro);        
        this.hideModal();
        this.cargando = false;        
        this.listar();
        this.$alert("Se editó correctamente","Atención","success"); 
      } catch (error) {
        console.log(error);
        this.cargando = false;
      }
    },
    baja(item) {
      this.$confirm("El usuario no podrá ingresar al sistema, ¿Está seguro?", "Atención", "warning", { confirmButtonText: 'SI',cancelButtonText: 'Cancelar' }).then(async (result) => {        
        if (result) {
          try {
            await this.axios.patch(`/usuario/${item.idusuario}`);
            this.listar();
            this.$alert("Usuario Inactivo","Atención","success");  
          } catch (error) {
            console.log(error);
          }
        }
      }).catch(()=>{});
    },
    alta(item) {
      this.$confirm("El usuario tendrá acceso al sistema, ¿Está seguro?", "Atención", "question", { confirmButtonText: 'SI',cancelButtonText: 'Cancelar' }).then(async (result) => {        
        if (result) {
          try {
            await this.axios.patch(`/altaUsuario/${item.idusuario}`);
            this.listar();
            this.$alert("Usuario Activo","Atención","success");  
          } catch (error) {
            console.log(error);
          }
        }
      }).catch(()=>{});
    },
    reset(item) {
      this.$confirm("La contraseña del usuario será restaurada por defecto (Nro Documento), ¿Está seguro?", "Atención", "question", { confirmButtonText: 'SI',cancelButtonText: 'Cancelar' }).then(async (result) => {        
        if (result) {
          try {
            let res = await this.axios.patch(`/reset/${item.idusuario}`);
            if(res.data.codigo==1){
              this.listar();
              this.$alert("Contraseña restaurada","Atención","success");  
            }else{
              this.$alert(res.data.mensaje,"Atención","error");  
            }
           
          } catch (error) {
            console.log(error);
          }
        }
      }).catch(()=>{});
    },
    showModal(item) {
      if(item!=undefined){
        this.registro = item;
        this.edicion= true;
        this.$refs["modalAm"].show();
        this.tituloModal='Editar Registro';
      }else{
        this.registro = {
          usuario: '',
          rol: '',
          idpersona: undefined,
          id_reparticion: undefined
        };
        this.edicion= false;
        this.$refs["modalAm"].show();
        this.tituloModal='Nuevo Registro';
      }      
    },
    hideModal() {
      this.$refs["modalAm"].hide(); 
    }  
  },
  created(){
    this.listarReparticiones();
    this.listarPersonas();
    this.listar();
    this.roles = [
      {id:1, 'descripcion': 'ADMIN'},
      {id:2, 'descripcion': 'CAJA'},
      {id:3, 'descripcion': 'VENTANILLA'},
      {id:4, 'descripcion': 'CAJA-VENTANILLA'}
    ];
  },
  validations: {
    registro: {      
      usuario: { required, letras, minLength: minLength(5) },
      rol: { required},
      idpersona: {required},
      id_reparticion: {required}
    }
  }

}
</script>
